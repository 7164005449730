<!--
  Please refer to Pages/Overview/main.vue
  for use case reference
-->
<template>
  <div :class="['sm-shadow', search && 'outer-card']"> 
    <div v-if="search" class="search">
      <h4>{{ search.title }}</h4>
      <label id="Search" for="search">
        <span class="material-symbols-rounded">search</span>
        <input
          id="search"
          :placeholder="search.placeholder ?? 'Search by file name'"
          v-model="query"
          @input="queryHandler(false)"
          @keyup.enter="queryHandler(true)"
        />
      </label>
    </div>
      <table v-if="loading" class="loading" @click="hideAllDropdown">
      <thead>
        <tr>
          <th v-for="(col, id) in tableConfig.format" :key="`table-col-header-${id}`" :width="`${col.width}%`">
            <span class="loading-badge"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="n in 10" :key="n">
          <td v-for="(col, id) in tableConfig.format" :key="`table-col-data-${id}`" :width="`${col.width}%`">
            <span :style="{ opacity: (8-n)/10 }" class="loading-badge"></span>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else-if="tableData.length === 0" :style="{ height: `${minHeight}px` }" class="card sm-shadow">
      <div class="empty-placeholder">
        <img src="@/assets/misc/empty.svg" alt="No Content" />
        <p>{{ emptyText }}</p>
      </div>
    </div>

    <table v-else>
  <thead>
    <tr v-if="selectedRows.length === 0">
      <!-- Checkbox for select all -->
      <th width="5%" v-if="!hideCheckbox">
        <input type="checkbox" v-model="selectAllChecked" @click="toggleSelectAll" />
      </th>
      <th v-for="(col, id) in tableConfig.format" :key="`table-col-header-${id}`" :width="`${col.width}%`"
        :style="col.header === 'ID' ? { padding: '0' } : {}" :class="{'text-center': col.center}">
        <button
          v-if="col.button"
          :class="['w-100 d-flex align-items-center', !col.button && 'cursor-default']"
          @click="sortTableBy(id, [col.button, col.header])"
        >
          {{ col.header }}
          <span class="material-symbols-rounded sort">expand_all</span>
        </button>
        <p v-else>{{ col.header }}</p>
      </th>
    </tr>
    <tr v-else-if="selectedRows.length >= 1">
      <th width="2.5%" v-if="!hideCheckbox">
        <input v-if="selectedRows.length < 10" type="checkbox" v-model="selectChecked" @click="toggleCheckbox" class="unique-checkbox"/>
        <input v-else type="checkbox" v-model="selectAllChecked" @click="toggleSelectAll" />
      </th>
      <th>
        <button v-if="showButtons" class="checkbox-button me-2" @click="handleBulkBookmark">
          <p class="checkbox-button-text">Bulk Bookmark</p>
        </button>
        <button v-if="showButtons" class="checkbox-button me-2" @click="handleArchiveAll">
          <p class="checkbox-button-text">Archive All</p>
        </button>
        <button v-if="showButtons || deleteAll" class="checkbox-button me-2" @click="$bvModal.show('delete-modal-button')">
          <p class="checkbox-button-text">Delete All</p>
        </button>
        <button v-if="unbookmarkAll" class="checkbox-button me-2" @click="handleUnbookmarkAll">
          <p class="checkbox-button-text">Unbookmark All</p>
        </button>
        <button v-if="unarhiveAll" class="checkbox-button me-2" @click="handleUnarchiveAll">
          <p class="checkbox-button-text">Unarchive All</p>
        </button>
      </th>
      
    </tr>
  </thead>
  <tbody>
    <tr v-for="(data, index) in tableData" :key="index"> 
      <!-- Row checkbox -->
       <!-- {{data}} -->
      <td width="5%" v-if="!hideCheckbox">
        <input type="checkbox" v-model="selectedRows" :value="data[0]" />
      </td>
      <td
        v-for="(col, id) in tableConfig.format"
        :key="`table-col-data-${id}`"
        :width="`${col.width}%`"
        :class="{ 'text-center': col.center }"
        :style="col.header === 'ID' ? { padding: '8px 16px 8px 0' } : {}"
        class="cursor-pointer"
        @click="handleRowClick(data[0])"
      >
        <!-- Action column -->
        <span v-if="col.header === 'Action'">
          <!-- Progress Activity Icon -->
          <span
            v-if="(data[6] < 100) || (data[4] < 100)"
            class="material-symbols-rounded rotate-icon"
            style="cursor: default;"
            @click.stop
          >
            progress_activity
          </span>
          
          <!-- Dropdown Icon -->
          <span
            v-else
            :id="`DROPDOWN-${index}`"
            class="material-symbols-rounded cursor-pointer"
            @click.stop="toggleDropdown(index)"
          >
            more_horiz 
            <b-tooltip
              :target="`DROPDOWN-${index}`"
              triggers="click"
              placement="bottom"
              id="TableListDropdown"
              custom-class="DROPDOWN-MENU"
            >
              <div
                v-for="(option, o_id) in tableConfig.options"
                :key="`dropdown-option-${o_id}`"
                class="dropdown-option"
                @click.stop="performAction(o_id, index)"
              >
                <span class="material-symbols-rounded">{{ option.icon }}</span>
                <p>{{ option.label }}</p>
              </div>
            </b-tooltip>
          </span>
        </span>

        <!-- Other Columns -->
        <p v-else class="mb-0 ellipsis pe-none" show="false" @mouseover="toggleEllipse($event)" @mouseleave="toggleEllipse($event)">
          {{ col.header.includes("Date") ? $options.filters.date(data[id]) : data[id] }}
        </p>
      </td>
    </tr>
  </tbody>

      <thead v-if="tableConfig.pagination.total > 10 && !search">
        <BPagination
          v-model="tableConfig.pagination.current"
          :total-rows="tableConfig.pagination.total"
          :per-page="tableConfig.pagination.perPage"
          last-number
        />
      </thead>
    </table>

    <div v-if="!loading && tableConfig.pagination.total > 10 && search" class="search-mode">
      <BPagination
        v-model="tableConfig.pagination.current"
        :total-rows="tableConfig.pagination.total"
        :per-page="tableConfig.pagination.perPage"
        last-number
      />
    </div>

    <b-modal id="delete-modal-button" hide-header hide-footer centered>
      <div class="delete-prompt">
        <img src="@/assets/misc/delete.svg" alt="Delete" />
        <p style="text-align: center;">
          <b>Are you sure you want to delete the campaigns?</b><br />
            This action is permanent and cannot be undone.
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide('delete-modal-button')">
          Cancel
        </button>
        <button class="btn-danger" @click="handleDeleteAll">Delete</button>
      </div>
    </b-modal>
    
  </div>
</template>


<script>
import { BPagination } from "bootstrap-vue";
import { toggleEllipse } from "../../store/utils";
import table from "../../store/table.js";

export default {
  components: {BPagination},

  props: {
    percentageColumnIndex: {
      type: Number,
      required: false // Make it optional
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    unbookmarkAll: {
      type: Boolean,
      default: false
    },
    unarhiveAll: {
      type: Boolean,
      default: false
    },
    deleteAll: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Function,
      required: true
    },
    /**
     * determine table's state
     * [true] loading state
     * [false] empty/display state (based on the entries in tableData)
     */
    loading: { type: Boolean },
    hideCheckbox: { type: Boolean, default: false },

    /**
     * setup configuration for the table
     * please refer to data() section for detailed input spec
     */
    tableConfig: { type: Object },

    /**
     * processed table data for display
     * 
     * ** if there is 3 headers specified in the tableConfig,
     *    I would suggest adding data IDs at index [3] (which will not be displayed)
     *    because it can be used to keep track with its original data
     */
    tableData: { type: Array },

    /**
     * wrapper function to invoke action method
     * should be able to handle options in tableConfig.options
     */
    actions: {
      default: function (o = null, id = null) {
        return `[No Handling Function] entry=${id} option=${o}`;
      },
    },

    /**
     * text to be displayed when given data have no entry
     * (empty state)
     */
    emptyText: { default: "No Content" },

    /**
     * minimum <tbody> height for empty state
     * (
     *  display state's height will be automatically scaled:
     *  [min] base on the number of entries
     *  [max] fit to the page view
     * )
     */
    minHeight: { default: 345 },

    /**
     * enable search mode with specifying the configs
     * {
     *  title: <Title> // Title of the table
     *  placeholder: <Placeholder> // Place holder of the search bar
     * }
     */
    search: { default: false },
  },

  data() { return {
    // search mode
    query: "",
    selectedRows: [],
    selectAllChecked: false,
    selectChecked: true,

    /**
     * tableConfig.format = [
     *   // Header Spec
     *   {
     *     header: <Name>    // Title of the column
     *     width: [Number]   // n% width of the column
     *     button: [Boolean] // Whether this row can be used
     *                       // to sort the data or not
     *     center: [Boolean] // Whether the content of
     *                       // the column should be centered
     *   }
     * ]
     * 
     * 
     * tableConfig.options = [
     *   // Options in the Dropdown menu
     *   {
     *     icon: <Icon>    // Name of a valid Google Material Symbols
     *     label: <Option> // Display text of the Option
     *   }
     * ]
     */

    // store entry id that is currently opened
    dropdown: null,

    /**
     * store data sort config
     * sort[0]: column index
     * sort[1]: order
     *   [0] 1-9|a-z
     *   [1] 9-1|z-a
     */
    sort: [null, 0],
  }},

  methods: {
    emitEvent(eventName) {
      this.$emit(eventName);
    },

    handleBulkBookmark() {
      const payload = {
        ids: this.selectedRows
      }
      console.log("Payload:", payload);
      table.BookmarkAll(payload).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },

    handleUnbookmarkAll(){
      const payload = {
        ids: this.selectedRows
      }
      console.log("Payload:", payload);
      table.UnbookmarkAll(payload).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },

    handleUnarchiveAll(){
      const payload = {
        ids: this.selectedRows
      }
      console.log("Payload:", payload);
      table.UnarchiveCampaignAll(payload).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },  

    handleArchiveAll() {
      const payload = {
        ids: this.selectedRows
      }
      console.log("Payload:", payload);
      table.ArchiveCampaignAll(payload).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
        this.loadData();
      });
    },

    handleDeleteAll() {
      this.$bvModal.hide('delete-modal-button');
      const payload = {
        ids: this.selectedRows
      }
      this.loading = true;
      table.DeleteCampaignAll(payload).then((res) => {
        if (res.data.message === "Successful")
          this.$toast.success(res.data.message);
          this.loadData();
      });
    },

    isClickEnabled(data) {
      // Check if percentageColumnIndex is defined and if the percentage data is >= 100
      if (this.percentageColumnIndex !== undefined && data[this.percentageColumnIndex] < 100) {
        return false;
      }
      return true;
    },

    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },
    handleRowClick(rowData) {
      // Emit the row-click event
      if (this.isClickEnabled(rowData)) this.$emit('row-click', rowData);
    },
    toggleDropdown(id) {
      this.hideAllDropdown();
      this.dropdown = this.dropdown === id ? null : id;
    },

    toggleEllipse(e) { toggleEllipse(e); },

    sortTableBy(id, [flag, header]) {
      // skip when the column cannot be used for sorting
      if (!flag) return;

      // update column/order of sorting
      if (this.sort[0] !== id) {
        this.sort = [id, 0];
      } else {
        switch(this.sort[1]) {
          case 0: this.sort[1]++; break;
          case 1: this.sort[1]--; break;
        }
      }

      // clone & sort the data
      const Clone = [...this.tableData]
        .sort((a, b) =>
          (this.sort[1] === 0) !== (!header === "Date")
            ? a[id].localeCompare(b[id])
            : b[id].localeCompare(a[id])
        );

      this.$emit("sort-data", Clone);
    },

    performAction(o, id) {
      this.hideAllDropdown();
      this.actions(o, id);
    },

    // queryHandler(flag) {
    //   if (flag || !this.query.trim().length) this.$emit("send-query", this.query);
    // },

    queryHandler(flag) {
      if (flag || !this.query.trim().length) {
        this.$emit("send-query", this.query);
      }
    },
    
    toggleCheckbox() {
      this.selectedRows = [];
    },
    toggleSelectAll() {
    this.selectAllChecked = !this.selectAllChecked;
    this.selectedRows = this.selectAllChecked ? this.tableData.map(data => data[0]) : [];
  },

  }
}
</script>

<style scoped>
/* stop hover effect for loading table */
.loading tr:hover {
  background-color: white;
}

tbody {
  border: 1px solid var(--light-grey);
}

tbody tr:hover p,
tbody tr:hover .material-symbols-rounded {
  color: #0E4485 !important;
}


th p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
}

th input {
  margin: 0px 0 -3px 0px
}

.sort {
  margin-left: 3px;
  font-size: 14px;
  font-weight: 900;
}

.custom-checkbox {
  border-radius: 4px;
  background: var(--Primary-Blue, #0E4485);
  width: 20px;
  height: 20px;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.custom-checkbox:checked::before {
  content: "-";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
}

.checkbox-button {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Mid-Grey, #D1D2D5);
  background: var(--Neutral-White, #FFF);
}

.checkbox-button-text{
  color: var(--Primary-Dark-Blue, #000046);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

thead .b-pagination {
  margin: 10px 0px;
  justify-self: flex-end;
}

.search-mode {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>

<!-- Search Mode -->
<style scoped>
h4 {
  margin-bottom: 0px;
}

.outer-card {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
}

.search {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<!-- Search bar -->
<style scoped>
#Search {
  height: 44px;
  border: 1px solid var(--light-grey);
  border-radius: 8px;
  display: flex;
  align-items: center;
}

#Search span {
  margin: 12px;
  color: var(--dark-grey);
}

#Search input {
  width: calc(100% - 60px);
}
</style>

<style>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt img {
  background-color: var(--interface-grey);
  padding: 32px;
  border-radius: 8px;
  height: 164px;
  width: 100%;
  margin-bottom: 16px;
}

.delete-prompt p {
  margin-bottom: 0px;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}

#TableListDropdown {
  left: -165px !important;
  width: 200px;
}
.checkbox{
  width: 18px !important;
  height: 18px !important;
  border: 1px solid var(--dark-grey) !important;
}
.rotate-icon {
  display: inline-block;
  animation: rotate 1s linear infinite;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--primary) !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input[type="checkbox"]:not(.unique-checkbox) {
  width: 18px;
  height: 18px;
  margin: .5px 0px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Black, #383A3E);
  flex-shrink: 0;
  appearance: none; 
  cursor: pointer;
}

input[type="checkbox"]:checked:not(.unique-checkbox) {
  background-color: var(--primary);
}

input[type="checkbox"]:checked:not(.unique-checkbox)::after {
  content: '';
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: relative;
  left: 5px;
}

.unique-checkbox {
  width: 18px;
  height: 18px;
  margin: .5px 0px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Black, #383A3E);
  flex-shrink: 0;
  appearance: none; 
  cursor: pointer;
  position: relative;
}

.unique-checkbox:checked {
  background-color: var(--primary);
}

.unique-checkbox:checked::after {
  content: '-';
  display: block;
  font-size: 28px;
  color: white;
  position: absolute;
  top: 54%;
  left: 53%;
  transform: translate(-50%, -50%);
}

</style>